import { Section } from './../../../viewModels/section';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Sector } from 'src/app/viewModels/sector';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { SectionFormComponent } from './section-form/section-form.component';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.css']
})
export class SectionsComponent implements OnInit {
  items: Section[]=[];

  displayedColumns: string[] = [ 'designation', 'abbreviation','sector' ,'classroom', 'actions'];
  dataSource = new MatTableDataSource<Section>(this.items);
  selectedSector = 1;

  sectors : Sector[]= []
  constructor(
    private apis: HiararchyService,
    public translate: TranslateService,
    private router: Router,
    private hiararchyService : HiararchyService,
    private toastr: ToastrService,
    public dialog: MatDialog

  ) { }

  item: Section = new Section();
  show_form = false;


   ngOnInit(): void {

    this.getSectors()
  }


  getSectors(){
    this.apis.getHiararchySectors()
    .subscribe((data : Sector[])=>{
      this.sectors = data;
      if(this.sectors && this.sectors.length>0){
        this.selectedSector = this.sectors[0].id;
        this.getSections();
      }else{
        this.router.navigate(['/ums/sectors'])
        this.showInfo('sectors_not_found')
      }
    })

  }

  getSections() {

    this.apis.getSectorSections(this.selectedSector)
      .subscribe((data: Section[]) => {
        this.items = data;
        console.log(this.items)
        this.dataSource = new MatTableDataSource<Section>(this.items);
      })
  }




  save() {
    console.log(this.item)
    this.apis.createOrUpdateSection(this.item)
      .subscribe((data) => {
        this.getSections()
        this.showSuccess('saved');
        this.item = new Section();
      })
  }

  edit(element) {
    this.item = element
  }

  remove(id) {
    Swal.fire({
      title: this.translate.instant('alerts.delete_title'),
      text: this.translate.instant('alerts.delete_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('alerts.delete_yes'),
      cancelButtonText: this.translate.instant('alerts.delete_no')
    }).then((result) => {
      if (result.value) {
        this.apis.removeSection(id)
          .subscribe(() => {
            this.getSections();
            Swal.fire(
              this.translate.instant('alerts.deleted'),
              this.translate.instant('alerts.deleted_text'),
              'success'
            )
          })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant('alerts.canceled'),
          this.translate.instant('alerts.canceled_text'),
          'error'
        )
      }
    })
  }

  showSuccess(msg) {

    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg) {
    this.toastr.error(this.translate.instant((msg)))
  }
  showInfo(msg) {
    this.toastr.info(this.translate.instant((msg)))
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SectionFormComponent, {
      width: '500px',
      data:  this.item,

    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.createOrUpdateSection(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getSections();

            this.item = new Section()
          })
      }

    });
  }

  openEditDialog(subject): void {
    this.item = subject;
    const dialogRef = this.dialog.open(SectionFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.createOrUpdateSection(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getSections();

            this.item = new Section()
          })
      }

    });
  }

  filter(v){
    this.dataSource.filter = v.trim().toLowerCase();
  }

  changeSector(){
    console.log(this.selectedSector)
    this.getSections()
  }
}
