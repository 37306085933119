import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'src/app/viewModels/subject';
import { SubjectService } from 'src/app/apis/subject.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { Sector } from 'src/app/viewModels/sector';
import Swal from 'sweetalert2';
import { SubjectFormComponent } from './subject-form/subject-form.component';
import { ExcelService } from 'src/app/apis/excel/excel.service';
import { ConfigService } from 'src/app/apis/config.service';

declare var $ :any;
@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.css']
})
export class SubjectsComponent implements OnInit {

  items: Subject[];

  displayedColumns: string[] = ['subject_code', 'designation', 'abbreviation', 'subject_module' , 'coefficient',   'regimes' , 'type_ue' , 'sector' , 'semestre', 'actions'];
  dataSource;
  selectedSector : number;
  sectors : Sector[]= []
  constructor(
    private apis: SubjectService,
    private config : ConfigService,
    public translate: TranslateService,
    private router: Router,
    private hiararchyService : HiararchyService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private excelService : ExcelService,
    private establishmentService : EstablishmentService

  ) { }

  item: Subject = new Subject();
  show_form = false;

  week_types;
  excel_model_path = this.config.hostName+'/assets/excel_models/subjects_model.xlsx';
   ngOnInit(): void {

    this.getSectors()
  }

  getSectors(){
    this.hiararchyService.getHiararchySectors()
    .subscribe((data : Sector[])=>{
      this.sectors = data;
      if(this.sectors && this.sectors.length >0 ){
        this.selectedSector = this.sectors[0].id;
        this.getSubjects();

      }
    })

  }


  getSubjects() {

    this.apis.getSectorSubjects( this.selectedSector)
      .subscribe((data: Subject[]) => {
        this.items = data;
        console.log(this.items)
        this.dataSource = new MatTableDataSource<Subject>(this.items);
      })
  }




  save() {
    console.log(this.item)
    this.apis.createOrUpdateSubject(this.item)
      .subscribe((data) => {
        this.getSubjects()
        this.showSuccess('saved');
        this.item = new Subject();
      })
  }

  edit(element) {
    this.item = element
  }

  remove(id) {
    Swal.fire({
      title: this.translate.instant('alerts.delete_title'),
      text: this.translate.instant('alerts.delete_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('alerts.delete_yes'),
      cancelButtonText: this.translate.instant('alerts.delete_no')
    }).then((result) => {
      if (result.value) {
        this.apis.removeSubject(id)
          .subscribe(() => {
            this.getSubjects();
            Swal.fire(
              this.translate.instant('alerts.deleted'),
              this.translate.instant('alerts.deleted_text'),
              'success'
            )
          })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant('alerts.canceled'),
          this.translate.instant('alerts.canceled_text'),
          'error'
        )
      }
    })
  }

  showSuccess(msg  , nb?) {

    this.toastr.success((nb? nb : '' )+ this.translate.instant(msg))
  }

  showError(msg , nb?) {
    this.toastr.error((nb? nb : '' ) + this.translate.instant((msg)))
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(SubjectFormComponent, {
      width: '100%',
      maxWidth :'95vw',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
         this.apis.createOrUpdateSubject(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getSubjects();

            this.item = new Subject()
          })
      }else{
        this.item = new Subject()
      }

    });
  }

  openEditDialog(subject): void {
    this.item = {...subject}; // copy subject;
    this.item.regimes = subject.regimes.map((r)=> { return r.pivot});
    this.item.regimes.map((x , j)=> {if(x.group_id){
      let g = JSON.parse(x.group_id)
      this.item.regimes[j].group_id= g instanceof Array ? g : [g]}
      if(x.classrooms){

        this.item.regimes[j].classrooms= JSON.parse(x.classrooms)
      }
      if(x.blocks){

        this.item.regimes[j].blocks= JSON.parse(x.blocks)
      }
      if(x.equipments){
        this.item.regimes[j].equipments= JSON.parse(x.equipments)

      }
    })
    const dialogRef = this.dialog.open(SubjectFormComponent, {
      width: '95%',
      maxWidth: '95vw',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.createOrUpdateSubject(result)
          .subscribe((a) => {
            this.showSuccess('saved');
            this.getSubjects();
            console.log(a)
            this.item = new Subject()
          })
      }else{
        this.item = new Subject()
      }

    });
  }

  filter(v){
    this.dataSource.filter = v.trim().toLowerCase();
  }

  changeSector(){
    console.log(this.selectedSector)
    if(this.selectedSector){
      this.getSubjects()

    }else{
      this.items =[];
    }
  }

  handleFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {

      const bstr: string = e.target.result;
      const data = <any[]>this.excelService.importFromFile(bstr);

      const header = data[0];
      //const header: string[] = Object.getOwnPropertyNames(new Subject());
      const importedData = data.slice(1, -1);


      let imported_subjects = importedData.map(arr => {

        const obj = {};
         for (let i = 0; i < header.length; i++) {
          const k = header[i];
          obj[k] = arr[i];
        }
        return <Subject>obj;
      })

     console.log(imported_subjects)
        this.apis.importSubjects(imported_subjects)
      .subscribe((data : any)=>{
        console.log(data)
        if(this.selectedSector){
          this.getSubjects();
        }

        if(data && data.imported_rows > 0){
          this.showSuccess( 'files_imported' , data.imported_rows)
        }
        if(data && data.failed_rows > 0){
          this.showError( 'failed_imported' , data.failed_rows)
        }
      });


    };
    reader.readAsBinaryString(target.files[0]);

  }
  import(){
    $('#file').click();
  }

  export(){
    this.excelService.exportToFile('blocks' , 'export_id');
  }


}
