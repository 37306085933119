import { Section } from "./section";

export class GroupTp {
    id : number
    designation : string
    abbreviation : string
    section_id : number = 1;
    classroom_id : number = 1;
    establishment_id : number = JSON.parse(localStorage.getItem('user-infos')).establishment_id;

    // view data
    section : Section
    sector_id : number
    group_td_id? : number
}
