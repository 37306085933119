import { GroupTdFormComponent } from './group-td-form/group-td-form.component';
import { GroupTd } from './../../../viewModels/group-td';
import { TranslateService } from '@ngx-translate/core';
import { Section } from 'src/app/viewModels/section';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Sector } from 'src/app/viewModels/sector';

@Component({
  selector: 'app-groups-td',
  templateUrl: './groups-td.component.html',
  styleUrls: ['./groups-td.component.css']
})
export class GroupsTdComponent implements OnInit {

  items: GroupTd[] = [];

  displayedColumns: string[] = ['designation', 'abbreviation', 'section','classroom', 'actions'];
  dataSource = new MatTableDataSource<GroupTd>(this.items);
  selectedSection;
  selectedSector;
  sectors: Sector[] = [];

  sections: Section[] = []
  constructor(
    private apis: HiararchyService,
    public translate: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog

  ) { }

  item: GroupTd = new GroupTd();
  show_form = false;


  ngOnInit(): void {

    this.getSectors()
  }

  getSectors() {
    this.apis.getHiararchySectors()
      .subscribe((data: Sector[]) => {
        this.sectors = data;
        if (this.sectors && this.sectors.length > 0) {
          this.selectedSector = this.sectors[0].id;
          this.getSections();
        } else {

        }
      })
  }

  getSections() {
    if (this.selectedSector) {
      this.apis.getSectorSections(this.selectedSector)
        .subscribe((data: Section[]) => {
          this.sections = data;
          if (this.sections && this.sections.length > 0) {
            this.selectedSection = this.sections[0].id;
            this.getGrouptds();
          } else {

          }
        })
    }

  }

  getGrouptds() {
    if (this.getSections) {
      this.apis.getSectionGroupTds(this.selectedSection)
        .subscribe((data: GroupTd[]) => {
          this.items = data;
          this.dataSource = new MatTableDataSource<GroupTd>(this.items);
        })
    }

  }




  save() {
    console.log(this.item)
    this.apis.createOrUpdateGroupTd(this.item)
      .subscribe((data) => {
        this.getGrouptds()
        this.showSuccess('saved');
        this.item = new GroupTd();
      })
  }

  edit(element) {
    this.item = element
  }

  remove(id) {
    Swal.fire({
      title: this.translate.instant('alerts.delete_title'),
      text: this.translate.instant('alerts.delete_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('alerts.delete_yes'),
      cancelButtonText: this.translate.instant('alerts.delete_no')
    }).then((result) => {
      if (result.value) {
        this.apis.removeGroupTd(id)
          .subscribe(() => {
            this.getGrouptds();
            Swal.fire(
              this.translate.instant('alerts.deleted'),
              this.translate.instant('alerts.deleted_text'),
              'success'
            )
          })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant('alerts.canceled'),
          this.translate.instant('alerts.canceled_text'),
          'error'
        )
      }
    })
  }

  showSuccess(msg) {

    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg) {
    this.toastr.error(this.translate.instant((msg)))
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(GroupTdFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.createOrUpdateGroupTd(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getGrouptds();

            this.item = new GroupTd()
          })
      }

    });
  }

  openEditDialog(subject): void {
    this.item = subject;
    const dialogRef = this.dialog.open(GroupTdFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.createOrUpdateGroupTd(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getGrouptds();

            this.item = new GroupTd()
          })
      }

    });
  }

  filter(v) {
    this.dataSource.filter = v.trim().toLowerCase();
  }

  changeSector(){
    this.getSections();
  }
  changeSection() {

    this.getGrouptds()
  }
}
