import { Sector } from "./sector";

export class Section {
    id : number
    designation : string
    abbreviation : string
    sector_id : number = 1;
    classroom_id : number = 1;
    establishment_id : number = JSON.parse(localStorage.getItem('user-infos')).establishment_id;
    sector : Sector
}
