<div mat-dialog-title><h1 >{{translate.instant('groupTd_data')}}</h1></div>
<div mat-dialog-content >
    <form fxLayout="column" [formGroup]="form"  >

        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('designation')}}" [(ngModel)]="data.designation"
                required [formControlName]="'designation'">

        </mat-form-field>
        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('abbreviation')}}"  [(ngModel)]="data.abbreviation"
                required [formControlName]="'abbreviation'">

        </mat-form-field>
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('sector')" formControlName="sector_id"
                [(ngModel)]="data.sector_id" (selectionChange)="changeSector()">
                <mat-option *ngFor="let sector of sectors" [value]="sector.id">
                    {{sector.abbreviation}}</mat-option>
            </mat-select>


        </mat-form-field>


        <mat-form-field>
            <mat-select [placeholder]="translate.instant('section')" formControlName="section_id"
                [(ngModel)]="data.section_id">
                <mat-option *ngFor="let section of sections" [value]="section.id">
                    {{section.abbreviation}}</mat-option>
            </mat-select>


        </mat-form-field>

        <mat-form-field>
          <mat-select [placeholder]="translate.instant('classroom')" [formControlName]="'classroom_id'"
              [(ngModel)]="data.classroom_id">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let classroom of classrooms" [value]="classroom.id">
                  {{classroom.abbreviation}} ({{classroom.subject_type}})</mat-option>
          </mat-select>
          </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>

    <button  mat-raised-button color="basic"  class=" ums_basic_button form-cancel" (click)="onNoClick()">{{'buttons.no_thanks' | translate}}</button>
    <button  mat-raised-button color="primary" [disabled]="!form.valid" class="ums_primary_button form-save" [mat-dialog-close]="data" cdkFocusInitial>
     {{'buttons.save' | translate}}
    </button>
</div>

