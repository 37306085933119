import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { Classroom } from 'src/app/viewModels/classroom';
import { Section } from 'src/app/viewModels/section';
import { Sector } from 'src/app/viewModels/sector';

@Component({
  selector: 'app-section-form',
  templateUrl: './section-form.component.html',
  styleUrls: ['./section-form.component.css']
})
export class SectionFormComponent implements OnInit {


  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<SectionFormComponent>,

    @Inject(MAT_DIALOG_DATA) public data: Section,
    private apis: HiararchyService,
    private establishmentApis : EstablishmentService
  ) { }

  sectors: Sector[] = [];
  classrooms: Classroom[] = [];

  form: FormGroup = new FormGroup({
    'designation': new FormControl(Validators.required),
    'abbreviation': new FormControl(Validators.required),
    'sector_id': new FormControl(),
    'classroom_id': new FormControl()
  })
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

    this.apis.getHiararchySectors()
    .subscribe((data : Sector[])=>{
      this.sectors = data;

    })
    this.establishmentApis.getEstablishmentClassrooms()
    .subscribe((data : Classroom[])=>{
      this.classrooms = data.filter((x)=>x.subject_type.includes('C'));

    })
      //get sections
  }

}
